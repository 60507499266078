<template>
  <div class="dump-page animated fadeIn container">
    <h1>주소록 관리</h1>
    <b-input-group class="mt-4">
      <b-btn variant="primary" class="fl" @click.prevent="showAddPopup">그룹 추가</b-btn>
    </b-input-group>
    <table class="hover-table border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td style="width: 200px;">
            <span @click="sortBy('id')" class="sort-item" :class="{ asc: sortKey === 'id', desc: sortKey === '-id' }">
              그룹 생성일
            </span>
          </td>
          <td>
            <span @click="sortBy('name')" class="sort-item"
              :class="{ asc: sortKey === 'name', desc: sortKey === '-name' }">
              그룹 이름
            </span>
          </td>
          <td style="width: 200px;">
            <span @click="sortBy('totalCount')" class="sort-item"
              :class="{ asc: sortKey === 'totalCount', desc: sortKey === '-totalCount' }">
              인원수
            </span>
          </td>
          <td width="200px"></td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="   item    in    items   ">
          <td @click="goToDetail(item.id)">{{ item.createdAt | dateShortFormat }}</td>
          <td @click="goToDetail(item.id)">{{ item.name }}</td>
          <td @click="goToDetail(item.id)">{{ item.totalCount }}</td>
          <td class="text-center">
            <div style="display:flex;gap:6px;white-space: nowrap;">
              <b-btn variant="secondary" @click.prevent="goToDetail(item.id)">보기</b-btn>
              <b-btn variant="primary" @click.prevent="getAttachment(item)">그룹 다운로드</b-btn>
              <b-btn variant="danger" @click.prevent="deleteContact(item)">그룹 삭제</b-btn>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="20"
      :limit="10"
      class="mt-4 "
      @change="changePage"
    ></b-pagination> -->
    <b-modal ref="addModal" title="주소록 그룹 추가" centered hide-footer>
      <table class="border-table">
        <tr>
          <td width="160px">그룹명</td>
          <td>
            <b-form-input type="text" class="form-control" placeholder="이름" v-model="groupName" />
          </td>
        </tr>
      </table>

      <b-btn class="mt-3" ref="uploadBtn" variant="primary" block @click="create">추가</b-btn>
    </b-modal>
  </div>
</template>
<style>
.border-table .sort-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.border-table .sort-item.asc,
.border-table .sort-item.desc {
  color: #333;
  font-weight: 500;
}

.border-table .sort-item.desc::after {
  content: '▼';
  display: inline-block;
  padding-left: 5px;
  font-size: 9px;
}

.border-table .sort-item.asc::after {
  content: '▲';
  display: inline-block;
  padding-left: 5px;
  font-size: 9px;
}
</style>
<script>
import ContactService from '@/services/ContactService'

export default {
  name: 'Contacts',
  head: {
    script: [
      { type: 'text/javascript', src: 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.4/xlsx.full.min.js', async: true, body: false },
    ]
  },
  data() {
    return {
      items: [],
      totalCount: Number.MAX_SAFE_INTEGER,
      currentPage: null,
      files: null,
      groupName: '',
      isLoading: false,
      sortKey: null
    }
  },
  mounted() {
    document.body.classList.add('bg-white')
    this.getList()
  },
  methods: {
    async getList() {
      const response = await ContactService.list()
      if (response.status == 200) {
        this.sortKey = null
        this.items = response.data.list
        // this.totalCount = response.data.totalCount
        // this.sortBy(this.sortKey)
      }
    },
    async sortBy(sortKey) {
      let isReverse = false
      if (this.sortKey === sortKey) {
        isReverse = true
      }

      this.sortKey = (isReverse ? '-' : '') + sortKey
      this.items = this.items.sort((a, b) => {
        const aVal = a[sortKey]
        const bVal = b[sortKey]

        let retVal = null
        if (sortKey == 'name') {
          retVal = aVal.localeCompare(bVal)
        }
        else {
          retVal = aVal - bVal
        }

        return isReverse ? -retVal : retVal
      })
    },
    async create() {
      try {
        this.isLoading = true

        const response = await ContactService.create({ groupName: this.groupName })
        if (response.status !== 200) {
          return
        }
        this.getList()
        this.$refs['addModal'].hide()
      } catch (e) {
        // Do nothing
      } finally {
        this.isLoading = false
      }
    },
    async goToDetail(id) {
      this.$router.push('/contacts/' + id)
    },
    async deleteContact(item) {
      try {
        if (!confirm(`[${item.name}] 그룹을 삭제하시겠습니까?`)) {
          return false
        }
        this.isLoading = true
        const response = await ContactService.delete(item.id)
        if (response.status !== 200) {
          return false
        }
        this.getList()
        return false
      } catch (e) {
        // do nothing
      } finally {
        this.isLoading = false
      }
    },
    async getAttachment(item) {
      this.isLoading = true
      const t = this
      ContactService.getAttachment(item.id)
        .then(response => {
          t.isLoading = false
          const XLSX = window.XLSX
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(response.data.split('\n').map(row => row.split(',')));
          ws['!cols'] = [{ width: 20 }];
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, `런던문자_주소록_${item.name}.xlsx`)
        })
        .catch(() => {
          t.isLoading = false
        })
    },
    async showAddPopup() {
      this.files = null
      this.groupName = ''
      this.$refs['addModal'].show()
    },
    changeAddFiles(e) {
      this.files = e.target.files
    },
  },
  watch: {
    isLoading() {
      // this.$refs.uploadBtn.disabled = this.isLoading
    },
    files() { },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getUsers()
    },
  },
}
</script>
